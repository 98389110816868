<template>
  <div class="p-10" style="justify-content: center; display: flex">
    <cgu></cgu>
  </div>
</template>
<script>
const cgu = () => import("./cgu.vue");

export default {
  components: {
    cgu,
  },
};
</script>

<style>
h1,
h2 {
  display: block !important;
  margin: 15px auto 5px;
}
</style>
